import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    setChain: (state, action) => {
        state = action.payload
        return action.payload;
    },
    resetChain: () => initialState,
  },
});

export const { setChain, resetChainList } = chainSlice.actions;
export const selectChain = (state) => state.chain;

export default chainSlice.reducer;
