import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const versionPickerSlice = createSlice({
  name: 'versionPicker',
  initialState,
  reducers: {
    setVersionPicker: (state, action) => {
      state = action.payload
      return state
    },
    addNewVersion: (state, action) => {
      const { version, currentPromptIndex } = action.payload
      const newVersionArray = [...state]
      newVersionArray[currentPromptIndex].push(version)
      return newVersionArray
    },
        resetVersionPicker: () => initialState
  }
})

export const { setVersionPicker, addNewVersion, resetVersionPicker } = versionPickerSlice.actions

export const selectVersionPicker = (state) => {
  return state.versionPicker
}

export default versionPickerSlice.reducer
