import { configureStore, combineReducers } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import userSlice from './userSlice'
import variablesSlice from './variablesSlice'
import modelsSlice from './modelsSlice'
import promptChainSlice from './promptChainSlice'
import promptSlice from './promptSlice'
import versionPickerSlice from './versionPickerSlice'
import currentVersionSlice from './currentVersionSlice'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import chainSlice from './chainSlice'
import chainListSlice from './chainListSlice'

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  variables: variablesSlice,
  models:modelsSlice,
  chain: chainSlice,
  chainList: chainListSlice,
  promptChain: promptChainSlice,
  prompt: promptSlice,
  versionPicker: versionPickerSlice,
  currentVersion: currentVersionSlice
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware : (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})

export const persistor = persistStore(store)
