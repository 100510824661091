import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import AuthRoutes from './AuthRoutes'
import NonAuthRoutes from './NonAuthRoutes'
import PublicRoutes from './PublicRoutes'
import { ROUTES } from '../store/constants/routes'
import { Suspense } from 'react'
import { ClipLoader } from 'react-spinners'
import { selectUserDetails } from '../store/userSlice'
import { selectIsAuthenticated } from '../store/authSlice'
export const AuthRoute = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const userDetails = useSelector(selectUserDetails)
  const onboardUser = localStorage.getItem('knit-ai-onboarding-form')

  return isAuthenticated ? (
    onboardUser !== userDetails?.id ? (
      <Navigate to={ROUTES.ONBOARD_USER} replace />
    ) : (
      children
    )
  ) : (
    <Navigate to={ROUTES.HOME} replace />
  )
}

export const Loading = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <ClipLoader color="#333" size={36} />
    </div>
  )
}

export const NonAuthRoute = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const userDetails = useSelector(selectUserDetails)
  const onboardUser = localStorage.getItem('knit-ai-onboarding-form')

  return isAuthenticated ? (
    onboardUser !== userDetails?.id ? (
      <Navigate to={ROUTES.ONBOARD_USER} replace />
    ) : (
      <Navigate to={ROUTES.BUILD} replace />
    )
  ) : (
    children
  )
}

export const RouteHandler = () => {
  return (
    <Suspense fallback={<Loading />}>
      <PublicRoutes />
      <AuthRoutes />
      <NonAuthRoutes />
    </Suspense>
  )
}
