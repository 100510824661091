import { createSlice } from '@reduxjs/toolkit';
import { getOutputVariables } from '../utils/prompt';

const initialState = [];

const outputVariablesSlice = createSlice({
  name: 'outputVariables',
  initialState,
  reducers: {
    setOutputVariables: (state, action) => {
      const { chain } = action.payload;

      if (!chain) {
        throw new Error('Invalid arguments');
      }

      const opVariables = getOutputVariables(chain);
      // console.log('Storing Output Variables', { opVariables })
      return opVariables;
    },

    updateOutputVariable: (state, action) => {
      const { id, name, value } = action.payload;

      return state.map((opVariable) =>
        opVariable.id === id ? { ...opVariable, name: name ?? opVariable.name, value } : opVariable
      );
    },

    reset: () => initialState,
  },
});

export const { setOutputVariables, updateOutputVariable, reset } = outputVariablesSlice.actions;

export const selectOutputVariables = (state) => state.outputVariables;

export const selectOutputVariableByName = (name) => (state) =>
  state.outputVariables.find((v) => v.name === name) ?? {};

export default outputVariablesSlice.reducer;
