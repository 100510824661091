import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const promptChainSlice = createSlice({
  name: 'promptChain',
  initialState,
  reducers: {
    setPromptChain: (state, action) => {
      return action.payload;
    },
    updatePromptsInPromptChain: (state, action) => {
      const { updatedPrompts } = action.payload;
      let newPromptChain = { ...state };
      newPromptChain.prompts = updatedPrompts;
      return newPromptChain;
    },
    reset: () => initialState,
  },
});

export const { setPromptChain, updatePromptsInPromptChain, reset } = promptChainSlice.actions;

export const selectPromptChain = (state) => {
  return state.promptChain;
}

export default promptChainSlice.reducer;
