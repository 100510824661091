import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state = action.payload
      return action.payload;
    },
  },
});

export const { setIsAuthenticated } = authSlice.actions;
export const selectIsAuthenticated = (state) => state.auth;

export default authSlice.reducer;
