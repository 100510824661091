import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    setPromptSlice: (state, action) => {
      state = action.payload
      return action.payload;
    },
    setPromptSliceAtIndex: (state, action) => {
      const { index, value } = action.payload;
      const newArray = [...state];
      newArray[index] = value;
      return newArray;
    },
    reset: () => initialState,
  },
});

export const { setPromptSlice, setPromptSliceAtIndex, reset } = promptSlice.actions;

export const selectPromptSlice = (state) => {
  return state.prompt;
}

export default promptSlice.reducer;
