import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const availableModelsSlice = createSlice({
  name: 'availableModels',
  initialState,
  reducers: {
    setAvailableModels: (state, action) => {
      return action.payload;
    },
    resetAvailableModels: () => initialState,
  },
});

export const { setAvailableModels, resetAvailableModels } = availableModelsSlice.actions;
export const selectAvailableModels = (state) => {
  return state.models
};

export default availableModelsSlice.reducer;
