import { useEffect } from 'react'
import axios from '../../utils'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTES } from '../../store/constants/routes'
import BlueLogo from '../../assets/svgs/logo-blue.svg'
import { selectUserDetails } from '../../store/userSlice'
const SharedPromptView = () => {
  const navigate = useNavigate()
  const [queryParams, _] = useSearchParams()

  const user = useSelector(selectUserDetails)

  useEffect(() => {
    const publicChainId = queryParams.get('chain_id')

    const importChain = async () => {
      const config = {
        method: 'post',
        url: '/chain/import',
        data: {
          orgID: user.selectedOrg,
          promptChainPublicID: publicChainId
        },
        'x-auth-token': user.token,
      }

      try {
        const response = await axios(config)

        if (response.status === 200) {
          if (response.data.id) localStorage.setItem('data-tour-id', response.data.id)

          navigate(ROUTES.BUILD, { replace: true })
        }
      } catch (error) {
        console.error('Failed to import shared Prompt chain')
      }
    }

    if (publicChainId) {
      if (user?.selectedOrg) {
        importChain()
      } else {
        try {
          localStorage.setItem('shared-chain-id', publicChainId)
          navigate(ROUTES.LOGIN, { replace: true })
        } catch (err) {
          console.error('Failed to save shared chain id')
        }
      }
    }
  }, [user])

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <img className="mr-2 mb-4" src={BlueLogo} alt="purple logo" />
    </div>
  )
}
export default SharedPromptView
