import axios from 'axios'
import { store } from './index'
import { BASE_URL } from './config'

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 40000
})

export default instance

instance.interceptors.request.use(
  async (config) => {
    /**
     * sample config
     * const config = {
        method: 'get',
        url: '/user/me',
        headers: {
          'x-auth-token': userDetails.token,
        }
      }
    */
    // config.headers['x-auth-token'] = store.getState()?.userDetails?.token
    config.headers['Access-Control-Allow-Origin'] = '*'

    // console.log(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
