import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  chains: [],
  selectedChain: {
    chain_id: '',
    prompt_id: '',
    current_version_id: ''
  }
}

const chainListSlice = createSlice({
  name: 'chainList',
  initialState,
  reducers: {
    setChainListGlobal: (state, action) => {
      const { chains } = action.payload
      return {
        ...state,
        chains
      }
    },
    addChain: (state, action) => {
      const { chain } = action.payload
      return {
        ...state,
        chains: [...state.chains, chain]
      }
    },
    setSelectedChain: (state, action) => {
      const { chain_id, prompt_id, current_version_id } = action.payload
      return {
        ...state,
        selectedChain: {
          chain_id,
          prompt_id,
          current_version_id
        }
      }
    },
    updateChainName: (state, action) => {
      const { chain_id, name } = action.payload
      let newChains = state.chains.map((chain) => {
        if (chain.id === chain_id) {
          chain.name = name
        }
        return chain
      })
      return void {
        ...state,
        chains: newChains
      }
    },
    addPrompt: (state, action) => {
      const { chain_id, prompt } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      chain.prompts.push(prompt)
      chain.prompt_versions.push({
        prompt_id: prompt.id,
        versions: {
          version_id: 'Default',
          prompt: prompt,
          comment: 'Default Comment'
        }
      })
      return void {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    updatePromptInChain: (state, action) => {
      const { chain_id, prompt_id, prompt } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      chain.prompts = chain.prompts.map((p) => (p.id === prompt_id ? prompt : p))
      return void {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    updatePromptNameAction: (state, action) => {
      const { chain_id, prompt_id, name } = action.payload
      //need to update both version and prompt at same time
      let updatedChain = state.chains.find((chain) => chain.id === chain_id)
      updatedChain.prompts.forEach((prompt) => {
        if (prompt.id === prompt_id) {
          prompt.name = name
        }
      })
      //TODO : Update only the prompt version's prompt name
      // not all versions of that prompt
      updatedChain.prompt_versions.forEach((v) => {
        if (v.prompt_id === prompt_id) {
          v.versions.prompt.name = name
        }
      })
      return void {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? updatedChain : c))
      }
    },
    deletePrompt: (state, action) => {
      const { chain_id, prompt_id } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      chain.prompts = chain.prompts.filter((p) => p.id !== prompt_id)
      return void {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    updateModel: (state, action) => {
      const { chain_id, prompt_id, model } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      let prompt = chain.prompts.find((p) => p.id === prompt_id)
      prompt.model = prompt.model.map((m) => (m.id === model.id ? model : m))
      return {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    addVersion: (state, action) => {
      const { chain_id, version } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)

      if (chain) {
        const updatedPromptVersions = [...chain.prompt_versions, version]
        const updatedChain = { ...chain, prompt_versions: updatedPromptVersions }
        return {
          ...state,
          chains: state.chains.map((c) => (c.id === chain_id ? updatedChain : c))
        }
      }
      console.error(`Chain with chain_id ${chain_id} not found.`)
      return state
    },
    deleteVersion: (state, action) => {
      const { chain_id, version_id } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      chain.prompt_versions = chain.prompt_versions.filter((v) => v.id !== version_id)
      return {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    updateVersion: (state, action) => {
      const { chain_id, version_id, prompt_id, prompt, comment } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      chain.prompt_versions.forEach((v) => {
        if (v.versions.version_id === version_id && v.prompt_id === prompt_id) {
          v.versions.prompt = prompt
          v.versions.comment = comment
        }
      })
      return void {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    updateCurrentPromptVersion: (state, action) => {
      const { chain_id, prompt_id, version_id } = action.payload
      return void {
        ...state,
        selectedChain: {
          chain_id,
          prompt_id,
          current_version_id: version_id
        }
      }
    },
    addVisionPrompt: (state, action) => {
      const { chain_id, prompt_id, vision_prompt } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      let prompt = chain.prompts.find((p) => p.id === prompt_id)
      prompt.vision_prompts.push(vision_prompt)
      return {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    deleteVisionPrompt: (state, action) => {
      const { chain_id, prompt_id, vision_prompt_id } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      let prompt = chain.prompts.find((p) => p.id === prompt_id)
      prompt.vision_prompts = prompt.vision_prompts.filter((v) => v.id !== vision_prompt_id)
      return {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    updateVisionPrompt: (state, action) => {
      const { chain_id, prompt_id, vision_prompt_id, vision_prompt } = action.payload
      let chain = state.chains.find((chain) => chain.id === chain_id)
      let prompt = chain.prompts.find((p) => p.id === prompt_id)
      prompt.vision_prompts = prompt.vision_prompts.map((v) => (v.id === vision_prompt_id ? vision_prompt : v))
      return {
        ...state,
        chains: state.chains.map((c) => (c.id === chain_id ? chain : c))
      }
    },
    resetChainList: () => initialState
  }
})

export const {
  setChainListGlobal,
  addChain,
  setSelectedChain,
  addPrompt,
  updateChainName,
  updatePromptNameAction,
  updatePromptInChain,
  deletePrompt,
  updateModel,
  addVersion,
  deleteVersion,
  updateVersion,
  updateCurrentPromptVersion,
  addVisionPrompt,
  deleteVisionPrompt,
  updateVisionPrompt,
  resetChainList
} = chainListSlice.actions

export const selectChainList = (state) => state.chainList

export const selectCurrentChain = (state) => {
  const { selectedChain, chains } = state.chainList
  return chains.find((chain) => chain.id === selectedChain.chain_id)
}

export const selectCurrentPrompt = (state) => {
  const { selectedChain, chains } = state.chainList
  const chain = chains.find((chain) => chain.id === selectedChain.chain_id)
  return chain.prompts.find((prompt) => prompt.id === selectedChain.prompt_id)
}

export const selectCurrentVersion = (state) => {
  const { selectedChain } = state.chainList
  return selectedChain.current_version_id
}

export const selectCurrentVersionsForPicker = (state) => {
  const { selectedChain, chains } = state.chainList
  const chain = chains.find((chain) => chain.id === selectedChain.chain_id)

  if (!chain || !chain.prompt_versions || !chain.prompt_versions.length) {
    return []
  }

  let versions = []
  chain.prompts.forEach((prompt, index) => {
    let verArray = []
    chain.prompt_versions.forEach((version) => {
      if (version.prompt_id === prompt.id) {
        verArray.push(version.versions.version_id)
      }
    })
    versions.push(verArray)
  })
  return versions
}

export const selectCurrentVisionPrompt = (state) => {
  const { selectedChain, chains } = state.chainList
  const chain = chains.find((chain) => chain.id === selectedChain.chain_id)
  const prompt = chain.prompts.find((prompt) => prompt.id === selectedChain.prompt_id)
  return prompt.vision_prompts.find((visionPrompt) => visionPrompt.id === selectedChain.current_vision_prompt_id)
}

export default chainListSlice.reducer
