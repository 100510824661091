export const GOOGLE_CLIENT_ID = '897165799637-b2lb6a6br9eimih00lrhctfvgm1okcgr.apps.googleusercontent.com'
export const MIXPANEL_TOKEN = '971ebbc73e1bf0e8dfdbc412d55aabc5'

export const IS_DEV = process.env.NODE_ENV === 'development'

let base_url = ''
if (process.env.NODE_ENV === 'production') {
  base_url = 'https://api.getknit.ai/v1'
} else {
  base_url = 'https://staging.getknit.ai/v1'
}

// TODO: remove this
export const BASE_URL = 'https://staging.getknit.ai/v1'

// export const BASE_URL = "http://localhost:3001/v1"
