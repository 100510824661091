import { createSlice } from '@reduxjs/toolkit'

const initialState = "Default"

const currentVersionSlice = createSlice({
  name: 'currentVersion',
  initialState,
  reducers: {
    setCurrentVersion: (state, action) => {
      return action.payload
    }
  }
})

export const { setCurrentVersion } = currentVersionSlice.actions

export const selectCurrentVersion = (state) => {
  return state.currentVersion
}

export default currentVersionSlice.reducer
