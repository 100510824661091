import React from 'react'
import { useDispatch } from 'react-redux'
import { IS_AUTHENTICATED, USER } from '../../store/actions/actionConstants'
import BlueLogo from '../../assets/svgs/logo-blue.svg'
import KnitAIBlue from '../../assets/svgs/knit.ai-blue.svg'
import LoginImageBlue from '../../assets/svgs/login-image-blue.svg'
import axios from 'axios'
import { BASE_URL } from '../../config'
import { GoogleLogin } from '@react-oauth/google'
import { identifyUser, trackEvent } from '../../services/analytics'
import { EVENTS } from '../../services/analytics/events'
import { setIsAuthenticated } from '../../store/authSlice'
import { setUserDetails } from '../../store/userSlice'
const Auth = () => {
  const dispatch = useDispatch()

  const login = async (token) => {
    const payload = { token }
    try {
      const response = await axios.post(BASE_URL + '/auth/google', payload)
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return null
    }
    return null
  }

  const handleAuth = async (loginResponse, authenticated) => {

    if (!authenticated) {
      dispatch(setIsAuthenticated(false))

      return
    }

    const user = await login(loginResponse.credential)
    if (!user) {
      dispatch(setIsAuthenticated(false))
      return
    }

    identifyUser(user.email)
    trackEvent(EVENTS.LOGIN, { email: user.email })
    dispatch(setIsAuthenticated(true))
    dispatch(setUserDetails(user))
    identifyUser(user.email)

    // openSigninTallyForm(user)
  }

  return (
    <div className="flex w-screen h-screen items-center lg:justify-around">
      <div className="lg:flex lg:flex-col items-center justify-center m-6">
        <div className="w-[100%]">
          <img className="mr-2 mb-4" src={BlueLogo} alt="purple logo" />
          <img src={KnitAIBlue} style={styles.companyName} alt="knit.ai logo" />
        </div>
        <div style={styles.signInHeading} className="text-2xl lg:text-4xl mt-4 lg:mt-0">
          Sign in to start using Knit
        </div>
        <div style={styles.signInText} className="text-xs lg:text-base mt-2">
          You are just one step away from building production ready LLM products with ease.{' '}
        </div>

        <div className="w-[100%]">
          <GoogleLogin
            onSuccess={(credentialResponse) => handleAuth(credentialResponse, true)}
            onError={() => handleAuth(null, false)}
            useOneTap
            theme="filled_black"
          />
        </div>
      </div>
      <div className="hidden lg:flex">
        <img className="opacity-80" src={LoginImageBlue} alt="login img" />
      </div>
    </div>
  )
}

const styles = {
  signInHeading: {
    width: '100%',
    color: 'white',
    fontFamily: 'Poppins',
    fontWeight: '400',
    wordWrap: 'break-word'
  },
  signInText: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#C1C1C1',
    fontFamily: 'Poppins',
    fontWeight: '400',
    wordWrap: 'break-word',
    marginBottom: 16
  },
  companyName: {
    marginRight: 8,
    marginBottom: 8,
    width: '120px',
    height: '40px'
  }
}
export default Auth
