import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state = action.payload
      return action.payload;
    },
  },
});

export const { setUserDetails } = userSlice.actions;
export const selectUserDetails = (state) => state.user;

export default userSlice.reducer;
